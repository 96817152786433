<template>
  <div>
    <dashboard-page-title
      @addEvent="$router.push({ name: 'addDiscounts' })"
      :showAddBtn="$perHelper('coupons:create')"
    >
      {{ $t("discounts.discounts") }}
    </dashboard-page-title>
    <main-table
      :fields="fields"
      :list_url="'merchant/coupons'"
      paginationName="خصومات"
    ></main-table>
  </div>
</template>
<script>
import { core } from "@/config/pluginInit";
export default {
  components: {},
  mounted() {
    core.index();
  },
  data() {
    return {
      fields: [
        { label: this.$t("main.title"), key: "title", class: "text-start" },
        { label: this.$t("main.code"), key: "code", class: "text-start" },
        {
          label: this.$t("discounts.numberOfUses"),
          key: "total_used",
          class: "text-start",
        },
        {
          label: this.$t("discounts.minOrderDiscount"),
          key: "min_order_price",
          class: "text-start",
        },
        {
          label: this.$t("discounts.numberOfUsesForUser"),
          key: "max_user_used",
          class: "text-start",
        },
        {
          label: this.$t("discounts.startDate"),
          key: "start_at",
          class: "text-start",
          type: "date",
        },
        {
          label: this.$t("discounts.endDate"),
          key: "end_at",
          class: "text-start",
          type: "date",
        },
        {
          label: this.$t("main.created_at"),
          key: "created_at",
          class: "text-start",
          type: "date",
        },
        {
          label: this.$t("products.control"),
          key: "actions",
          type: "actions",
          class: "text-start",
          actions: [
            {
              text: "Delete",
              icon: "ri-delete-bin-line",
              color: "danger",
              url: "merchant/coupons",
              titleHeader: this.$t("main.item"),
              question: this.$t("main.deleteConfirmation"),
              textContent: "name",
              textDeleteButton: this.$t("main.delete"),
              textCancelButton: this.$t("main.cancel"),
              showAlert: true,
              // showIf: () => this.$perHelper("coupons:delete"),
            },
          ],
        },
      ],
    };
  },
  methods: {
    addMerchant() {
      console.log("hello");
    },
  },
};
</script>
